import { cx } from '../../../helpers/helper'
import AltLogoBox from '../../alt-logo-box'
import { newBackground } from '../landing-page-v2-box'
import { InstallButtonsBox } from './install-buttons-box'

export default function LandingPageV2Small() {
  return (
    <>
      <div
        className={cx(
          newBackground,
          'flex flex-col items-center justify-end',
          'w-screen h-screen relative min-h-[800px] min-w-[350px]',
          'overflow-clip',
        )}
      >
        <div className="absolute top-[5%] left-0 w-[50%] max-h-[70vh] overflow-hidden">
          <img
            src={'assets/landing-page/landing-page-hero.png'}
            alt="neon-man"
            className="h-full object-contain"
          />
        </div>

        <AltLogoBox className={'fill-white h-14 mt-8'} />
        <span className="text-lg mt-8 font-khula">smartest fashion search</span>
        <div className="flex flex-row justify-center gap-4 mt-8 mb-28">
          <InstallButtonsBox type="black" />
        </div>
        <div
          className={cx(
            'bg-[#D9D9D9] w-[400px] h-[400px] rounded-full',
            'absolute top-2 right-[-210px]',
            'flex flex-col items-start justify-center',
          )}
        >
          <div
            className={cx(
              'flex flex-col justify-center gap-4 ml-4 w-[40%]',
              'text-black text-[26px] font-molengo',
            )}
          >
            Tired Of Clutter On Fashion Apps?
          </div>
        </div>
      </div>
      <div
        className={cx(
          'bg-black',
          'w-screen h-screen min-h-[800px] min-w-[350px]',
          'flex flex-col items-center justify-center',
        )}
      >
        <div className="mt-10 text-2xl font-molengo">All in One</div>
        <div className="mt-2 mb-10 px-4 font-khula font-light">
          From Zara to homegrown brands - everything under one roof
        </div>
        <div className="m-4 max-w-[460px] mb-10">
          <img
            src={'assets/landing-page/malt-shelf.png'}
            alt="neon-man"
            className="w-full"
          />
        </div>
        <InstallButtonsBox type="white" />
      </div>
      <div
        className={cx(
          'w-screen h-screen min-h-[800px] min-w-[350px]',
          newBackground,
          'flex flex-col items-center justify-center',
        )}
      >
        <div className="text-2xl mt-10 font-molengo">Pick Your Favourites</div>
        <div className="mt-2 max-w-[375px] font-khula font-light">
          No clutter. Select only brands you like and discover similar ones
        </div>
        <div className="flex flex-col gap-4 w-full max-w-[400px] pt-10 pb-6 px-10">
          <a href="/brand-reviews">
            <img
              src={'assets/landing-page/zara.png'}
              alt="zara"
              className="w-full"
            />
          </a>
          <a href="/brand-reviews">
            <img
              src={'assets/landing-page/andamen.png'}
              alt="zara"
              className="w-full"
            />
          </a>
          <a href="/brand-reviews">
            <img
              src={'assets/landing-page/label-ritu-kumar.png'}
              alt="zara"
              className="w-full"
            />
          </a>
        </div>
        <a href="/brand-reviews">
          <div className="mt-2 mb-4 underline">View All Brands</div>
        </a>
        <InstallButtonsBox type="round" />
      </div>
      <div
        className={cx(
          'w-screen min-w-[350px]',
          'bg-black',
          'flex flex-col items-center',
        )}
      >
        <div className="text-2xl mt-10 font-molengo">Best Prices</div>
        <div className="mt-4 font-khula font-light">
          Compare prices across trusted stores.
          <br />
          Never miss a price drop
        </div>
        <div
          className={cx(
            'w-screen flex flex-row gap-2',
            'items-center justify-center overflow-clip',
            'my-10',
          )}
        >
          <img
            src={'assets/landing-page/pdp-left-cut.png'}
            alt="pdp-left"
            className="h-[90%]"
          />
          <img
            src={'assets/landing-page/pdp-center.png'}
            alt="pdp-left"
            className="flex-1 h-full"
          />
          <img
            src={'assets/landing-page/pdp-right-cut.png'}
            alt="pdp-left"
            className="h-[90%]"
          />
        </div>
        <div className="m-2 flex-1">
          <img
            src={'assets/landing-page/price-compare.png'}
            alt="price-compare"
            className=""
          />
        </div>
        {/* <div className="w-full mt-8">
          <LoginPromptBox text="Try ALT" />
        </div> */}
      </div>
    </>
  )
}
