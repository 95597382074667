import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { logAnalyticsEvent } from '../../data/analytics'
import { getPwaOrNot } from '../../helpers/device-helper'
import { cx } from '../../helpers/helper'
import { useWindowHeight } from '../../hooks/window-height'
import { useWindowWidth } from '../../hooks/window-width'
import { InstallButtonsBox } from './components/install-buttons-box'
import LandingPageV2Large from './components/landing-page-v2-large'
import LandingPageV2Small from './components/landing-page-v2-small'
import LandingPageFooter from './components/lanidng-page-footer'

export const newBackground = 'bg-[#BE3455]'

export default function LandingPageV2Box() {
  const [isPWA, setIsPWA] = useState(false)

  const router = useRouter()

  useEffect(() => {
    logAnalyticsEvent('landing_page_view')
    setIsPWA(getPwaOrNot())
  }, [])

  const width = useWindowWidth()
  const height = useWindowHeight()

  const isDesktop = width > 500 && height < width

  return (
    <div
      className={cx(
        'text-white text-center',
        'flex flex-col justify-center items-center w-full',
        'font-khula',
        'tracking-[0.08em]',
      )}
    >
      {isDesktop ? <LandingPageV2Large /> : <LandingPageV2Small />}
      <div
        className={cx(
          'w-full bg-black flex flex-col items-center pb-16',
          isDesktop ? '' : 'pt-16',
        )}
      >
        <div
          className={cx(
            isDesktop ? 'text-[46px]' : 'text-2xl',
            'font-montserrat px-2',
          )}
        >
          100% On ALT
        </div>
        <div
          className={cx(
            isDesktop ? 'text-2xl' : '',
            'font-khula font-thin',
            'mt-4 px-2',
          )}
        >
          Stores like Myntra only have 20% of the latest collections
        </div>
      </div>
      <div
        className={cx('w-full flex flex-col items-center py-8', newBackground)}
      >
        <div
          className={cx(
            isDesktop ? 'text-[46px]' : 'text-2xl',
            'mb-4 font-molengo',
          )}
        >
          Download the app
        </div>
        <InstallButtonsBox type="black" />
      </div>
      <LandingPageFooter />
      <div className="bg-black w-screen">
        <div className="text-left text-xs px-4 pt-2 pb-6 font-light">
          © 2024 Myalt Retail Tech Private Limited.
        </div>
      </div>
    </div>
  )
}
