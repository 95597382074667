import Link from 'next/link'
import { cx } from '../../../helpers/helper'
import { useWindowHeight } from '../../../hooks/window-height'
import { useWindowWidth } from '../../../hooks/window-width'

export default function LandingPageFooter() {
  const width = useWindowWidth()
  const height = useWindowHeight()

  const isDesktop = width > 500 && height < width

  return (
    <div
      className={cx(
        'w-screen bg-black pt-12 px-6',
        'flex  items-start',
        'text-left text-[16px]',
        'gap-4 text-xs font-khula',
        isDesktop ? ' justify-between flex-row' : 'justify-start flex-col',
      )}
    >
      <div>
        <div className="font-semibold ">Contact Us</div>
        <div className="font-light mt-3">+91 9967705391 | hello@the-alt.co</div>
      </div>
      <div className="font-light">
        <span className="font-semibold">Legal</span>
        <div className="flex flex-col gap-2 mt-3">
          <Link href={'/terms'} prefetch={false}>
            <a>
              <div>Terms & Conditions</div>
            </a>
          </Link>
          <Link href={'/privacy'} prefetch={false}>
            <a>
              <div>Privacy Policy</div>
            </a>
          </Link>
          <Link href={'/for-brands'} prefetch={false}>
            <a>
              <div>For Brands</div>
            </a>
          </Link>
        </div>
      </div>
      <div>
        <div className="font-semibold">Follow us on social media</div>
        <div className="flex flex-row py-3">
          <a
            href="https://www.instagram.com/myalt.finds"
            target="_blank"
            rel="noreferrer"
          >
            <img src={'/assets/landing-page/insta.svg'} alt="Instagram" />
          </a>
          <a
            href="https://www.youtube.com/@myaltshop"
            target="_blank"
            rel="noreferrer"
          >
            <img src={'/assets/landing-page/yt.svg'} alt="Youtube" />
          </a>
        </div>
      </div>
    </div>
  )
}
