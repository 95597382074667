import { cx } from '../../../helpers/helper'
import AltLogoBox from '../../alt-logo-box'
import { newBackground } from '../landing-page-v2-box'
import { InstallButtonsBox } from './install-buttons-box'

export default function LandingPageV2Large() {
  return (
    <>
      <div
        className={cx(
          newBackground,
          'flex flex-col items-center justify-center',
          'w-screen h-screen relative',
          'overflow-clip',
        )}
      >
        <div className="absolute bottom-0 top-0 left-0 h-full">
          <img
            src={'assets/landing-page/landing-page-hero.png'}
            alt="alt-fashion"
            className="h-full"
          />
        </div>
        <div className="flex w-screen flex-col items-center mb-10 mx-5 z-10">
          <span className="text-[40px] font-molengo">
            Tired Of <br />
            Clutter On Fashion Apps?
          </span>
          <AltLogoBox className={'fill-white h-14 my-12'} />
          <span className="mb-10 text-2xl font-light">
            smartest fashion search
          </span>
          <InstallButtonsBox type="black" />
        </div>
      </div>
      <div
        className={cx(
          'bg-black',
          'w-screen h-screen',
          'flex flex-row items-center justify-around',
          'px-10',
        )}
      >
        <div className="flex flex-col text-left p-8">
          <div className="mt-10 text-[46px] font-molengo">All In One</div>
          <div className="mt-2 mb-10 text-2xl font-light">
            From Zara to homegrown brands - everything under one roof
          </div>
          <InstallButtonsBox type="white" />
        </div>

        <div className="m-4 w-[30vw]">
          <img
            src={'assets/landing-page/malt-shelf.png'}
            alt="Alt Fashion"
            className="w-[50vw]"
          />
        </div>
      </div>
      <div
        className={cx(
          'w-screen h-screen',
          newBackground,
          'flex flex-col items-center justify-center',
        )}
      >
        <div className="text-[46px] font-molengo">Pick Your Favourites</div>
        <div className="text-2xl mt-2 font-light">
          No clutter. Select only brands you like and discover similar ones
        </div>
        <div className="flex flex-row gap-4 w-full py-10 px-10 justify-center">
          <a href="brand-reviews">
            <img
              src={'assets/landing-page/zara.png'}
              alt="zara"
              className="w-full"
            />
          </a>
          <a href="brand-reviews">
            <img
              src={'assets/landing-page/andamen.png'}
              alt="zara"
              className="w-full"
            />
          </a>
          <a href="brand-reviews">
            <img
              src={'assets/landing-page/label-ritu-kumar.png'}
              alt="zara"
              className="w-full"
            />
          </a>
        </div>
        <a href="/brand-reviews">
          <div className="mt-2 mb-4 underline">View All Brands</div>
        </a>
        <InstallButtonsBox type="black" />
      </div>
      <div
        className={cx(
          'w-screen h-screen',
          'bg-black text-left',
          'flex flex-row items-center gap-4',
        )}
      >
        <img
          src={'assets/landing-page/pdp-left-cut.png'}
          alt="pdp-left"
          className="w-6"
        />

        <img
          src={'assets/landing-page/pdp-center.png'}
          alt="pdp-left"
          className="w-[25%]"
        />
        <div className="flex-1 flex items-center justify-center">
          <div className="flex flex-col items-start max-w-[600px] px-10">
            <div className="">
              <img
                src={'assets/landing-page/price-compare.png'}
                alt="price-compare"
                className=""
              />
            </div>
            <div className="text-[46px] mt-8 font-molengo">Best Prices</div>
            <div className="text-2xl mt-4 font-light mb-4">
              Compare prices across trusted stores.
              <br />
              Never miss a price drop
            </div>
            <InstallButtonsBox type="black" />
          </div>
        </div>

        <img
          src={'assets/landing-page/pdp-right-cut.png'}
          alt="pdp-left"
          className="w-6"
        />
      </div>
    </>
  )
}
